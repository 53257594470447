<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <!-- 캠페인 관리 -->
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">캠페인 관리
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2 ml-2">조회</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="140px" />
                  <col width="148px" />
                  <col width="140px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">건설사명</v-badge>
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사업지명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp free">
                        <v-select
                          :items="DROPITEMS_2"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">캠페인명</v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">캠페인 목록
              <div class="ml-auto">
                <v-btn outlined small class="btn-default">신규</v-btn>
                <v-btn outlined small class="btn-etc ml-2" disabled>삭제</v-btn>
              </div>
            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                height="210px"
                fixed-header
                hide-default-footer
                :headers="gridDataHeaders"
                :items="FnRowNum(gridDataText)"
                :items-per-page="30"
                item-key="name"
                :page.sync="page"
                class="grid-default"
                @page-count="pageCount = $event"
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>

        </div>
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">캠페인 상세내용
            <div class="ml-auto">
              <v-btn outlined small class="btn-point ml-2">저장</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="300px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      건설사명
                    </th>
                    <td >
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      사업지명
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      캠페인타입
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row">
                      기간
                    </th>
                    <td>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="calendar-modal right"
                        color="#FFF"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <v-text-field
                              v-model="dateRangeText"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              outlined
                              hide-details
                              class="form-inp icon-calendar"
                              append-icon="svg-calendar"
                            ></v-text-field>
                            <!-- 새로고침 버튼 -->
                            <v-btn
                              dark
                              small
                              class="btn-main-refresh pa-0 ml-2"
                              min-width="32px"
                              height="32px"
                              plain
                            >
                              <v-icon small class="svg-main-refresh"></v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <div class="datepicker-range">
                          <v-date-picker
                            v-model="dates[0]"
                            no-title
                            dark
                            color="#F7AFAE"
                            @change="startDate"
                            :first-day-of-week="1"
                            locale="ko-KR"
                            :day-format="mixin_getDate"
                          ></v-date-picker>
                          <v-date-picker
                            v-model="dates[1]"
                            dark
                            color="#F7AFAE"
                            no-title
                            show-current="false"
                            :min="dates[0]"
                            @change="endDate"
                            :first-day-of-week="1"
                            locale="ko-KR"
                            :day-format="mixin_getDate"
                          ></v-date-picker>
                        </div>
                        <div class="text-center pb-3">
                          <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
                        </div>
                      </v-menu>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">캠페인명</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                    <th scope="row">상태</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">메인이미지</th>
                    <td>
                      <div>
                        <v-file-input
                          class="mt-0"
                          dense
                          hide-details
                          prepend-icon=""
                          height="32"
                        ></v-file-input>
                      </div>
                    </td>
                    <th scope="row">상담신청이미지</th>
                    <td>
                      <v-file-input
                        class="mt-0"
                        dense
                        hide-details
                        prepend-icon=""
                        height="32"
                      ></v-file-input>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">서비스로고</th>
                    <td></td>
                    <th scope="row">버튼텍스트</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">중복참여여부</th>
                    <td>
                      <v-checkbox
                        :on-icon="'svg-chk-on'"
                        :off-icon="'svg-chk-off'"
                        label=""></v-checkbox>
                    </td>
                    <th scope="row">완료링크</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">최대예약</th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        name="name" label="" outlined >
                      </v-text-field>
                    </td>
                    <th scope="row">챗봇타입</th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                          :items="DROPITEMS_1"
                          item-text="title"
                          outlined
                          hide-details
                          placeholder="선택하세요"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
      <!-- 예약시간설정 탭 -->
      <div class="box-wrap">
        <v-tabs
          class="tabs-form"
          slider-color="#F1B02A"
          slider-size="3"
        >
          <v-tab>예약시간설정</v-tab>
          <v-tab>알림톡설정</v-tab>
          <v-tab>참여 허용된 전화번호</v-tab>

          <v-tab-item>
            <!-- 예약시간설정 -->
            <div class="box-ct py-5">
              <div class="in-field">
                <span class="in-label">예약기간</span>
                <span class="in-value">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-text-field
                          v-model="dateRangeText"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          hide-details
                          class="form-inp icon-calendar pt-0"
                          append-icon="svg-calendar"
                        ></v-text-field>
                        <!-- 새로고침 버튼 -->
                        <v-btn
                          dark
                          small
                          class="btn-main-refresh pa-0 ml-2"
                          min-width="32px"
                          height="32px"
                          plain
                        >
                          <v-icon small class="svg-main-refresh"></v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <div class="datepicker-range">
                      <v-date-picker
                        v-model="dates[0]"
                        no-title
                        dark
                        color="#F7AFAE"
                        @change="startDate"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-date-picker
                        v-model="dates[1]"
                        dark
                        color="#F7AFAE"
                        no-title
                        show-current="false"
                        :min="dates[0]"
                        @change="endDate"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                    </div>
                    <div class="text-center pb-3">
                      <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
                    </div>
                  </v-menu>
                </span>
              </div>
              <div class="table-form mt-2">
                <table>
                  <caption class="hide">
                    사용자정보 항목
                  </caption>
                  <colgroup>
                    <col width="148px" />
                    <col width="280px" />
                    <col width="148px" />
                    <col width="280px" />
                    <col width="148px" />
                    <col width="280px" />
                    <col width="148px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        시간범위
                      </th>
                      <td >
                        <div class="d-flex align-center">
                          <div class="form-inp free">
                            <v-select
                              :items="DROPITEMS_2"
                              item-text="title"
                              outlined
                              hide-details
                              placeholder="선택하세요"
                            >
                            </v-select>
                          </div>
                          <div class="px-1"> ~ </div>
                          <div class="form-inp free">
                            <v-select
                              :items="DROPITEMS_2"
                              item-text="title"
                              outlined
                              hide-details
                              placeholder="선택하세요"
                            >
                            </v-select>
                          </div>
                        </div>
                      </td>
                      <th scope="row">
                        최대상담인원
                      </th>
                      <td>
                        <div class="form-inp free">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        시간단위
                      </th>
                      <td>
                        <div class="form-inp free">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        상담제외시간
                      </th>
                      <td>
                        <div class="form-inp free">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-2">
                <v-textarea
                  class="textarea-type0"
                  label=""
                  placeholder="내용을 입력해주세요"
                  no-resize
                  outlined
                ></v-textarea>
              </div>
              <div class="in-field mt-2">
                <span class="in-label">기간</span>
                <span class="in-value">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-text-field
                          v-model="dateRangeText"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          hide-details
                          class="form-inp icon-calendar pt-0"
                          append-icon="svg-calendar"
                        ></v-text-field>
                        <!-- 새로고침 버튼 -->
                        <v-btn
                          dark
                          small
                          class="btn-main-refresh pa-0 ml-2"
                          min-width="32px"
                          height="32px"
                          plain
                        >
                          <v-icon small class="svg-main-refresh"></v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <div class="datepicker-range">
                      <v-date-picker
                        v-model="dates[0]"
                        no-title
                        dark
                        color="#F7AFAE"
                        @change="startDate"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-date-picker
                        v-model="dates[1]"
                        dark
                        color="#F7AFAE"
                        no-title
                        show-current="false"
                        :min="dates[0]"
                        @change="endDate"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                    </div>
                    <div class="text-center pb-3">
                      <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
                    </div>
                  </v-menu>
                </span>
              </div>
              <div class="table-form mt-2">
                <table>
                  <caption class="hide">
                    사용자정보 항목
                  </caption>
                  <colgroup>
                    <col width="148px" />
                    <col width="280px" />
                    <col width="148px" />
                    <col width="280px" />
                    <col width="148px" />
                    <col width="280px" />
                    <col width="148px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        시간별 최대예약
                      </th>
                      <td >
                        <v-text-field
                          class="form-inp full"
                          name="name" label="" outlined >
                        </v-text-field>
                      </td>
                      <th scope="row">
                        2022년 6월 2일 제외시간
                      </th>
                      <td>
                        <div class="form-inp free">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        총예약인원
                      </th>
                      <td colspan="3">
                        <v-text-field
                          class="form-inp full"
                          name="name" label="" outlined >
                        </v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <!-- 알림톡설정 -->
            <div class="box-ct mt-2">
              <div class="table-form d-flex">
                <table>
                  <caption class="hide">
                    사용자정보 항목
                  </caption>
                  <colgroup>
                    <col width="148px" />
                    <col width="300px" />
                    <col width="148px" />
                    <col width="300px" />
                    <col width="148px" />
                    <col width="300px" />
                    <col width="148px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        방문예약알림
                      </th>
                      <td >
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_1"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        1일전 알림
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        취소알림
                      </th>
                      <td >
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_1"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        상담신청
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        내앞대기자
                      </th>
                      <td >
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_1"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        대기알림
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        상담석(호출)
                      </th>
                      <td >
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_1"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                      <th scope="row">
                        미처리
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            :items="DROPITEMS_2"
                            item-text="title"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <!-- 참여 허용된 전화번호 -->
            <div class="d-flex justify-end mt-2">
              <v-btn outlined small class="btn-point">샘풀 다운로드</v-btn>
              <v-btn outlined small class="btn-default ml-2">업로드</v-btn>
              <v-btn outlined small class="btn-default ml-2">신규</v-btn>
              <v-btn outlined small class="btn-etc ml-2">삭제</v-btn>
              <v-btn outlined small class="btn-point ml-2">저장</v-btn>
            </div>
            <div class="mt-2">
              <v-data-table
                dense
                height="210px"
                fixed-header
                show-select
                hide-default-footer
                :headers="gridDataHeaders2"
                :items="FnRowNum(gridDataText2)"
                :items-per-page="30"
                item-key="index"
                :page.sync="page"
                class="grid-default"
                @page-count="pageCount = $event"
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText2.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
        <div class="box-ct">

        </div>

      </div>

    </div>

  </main>
</template>

<script>
  export default {
    name: "MENU_CAM0100_pu", //name은 'MENU_' + 파일명 조합
    components: {

    },
    data() {
      return {
        menu1: false,
        menu2: false,
        menu3: false,
        dates: [
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        ],
        gridDataHeaders: [
          { text: 'NO', value: 'index', align: 'center',width: '60px'  },
          { text: '설문', value: 'index01', align: 'center'},
          { text: '사업지명', value: 'index02', align: 'center'},
          { text: '캠페인타입', value: 'index03', align: 'center'},
          { text: '사업지URL', value: 'index04', align: 'center'},
          { text: '이미지', value: 'index05', align: 'center'},
          { text: '시작일', value: 'index06', align: 'center'},
          { text: '종료일', value: 'index07', align: 'center'},
        ],
        gridDataHeaders2: [
          { text: 'NO', value: 'index', align: 'center', width: '60px' },
          { text: '이름', value: 'index01', align: 'center'},
          { text: '전화번호', value: 'index02', align: 'center'},
        ],
        gridDataText: [

          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
            index07: 'index07',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
            index07: 'index07',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
            index07: 'index07',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
            index07: 'index07',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
            index07: 'index07',
          },
          {
            index01: 'index1111111',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
            index05: 'index05',
            index06: 'index06',
            index07: 'index07',
          },
        ],
        gridDataText2: [
          {
            index01: '홍길동',
            index02: '000-0000-0000',
          },
          {
            index01: '홍길동2',
            index02: '000-0000-0000',
          },
          {
            index01: '홍길동3',
            index02: '000-0000-0000',
          },

        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 10,

      };
    },

    mounted() {
    },

    methods: {
      FnRowNum(data) {
        return data.map(
          (data, index) => ({
          ...data,
          index: index + 1
        }))
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${year}/${month}/${day}`
      },
      startDate(e){
        this.dates[0] = e;
      },
      endDate(e){
        this.dates[1] = e;
      },

    },

    computed: {
      dateRangeText () {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + ' ~ ' + newEndDate;
        // return this.dates.join(' ~ ')
      },

    },
  };
</script>

<style>

</style>